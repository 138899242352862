<template>
  <div class="wh-space-bottom">
    <b-card>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </b-card>

    <b-card>
      <b-tabs v-model="tabIndex" lazy @input="onTabChange">
        <b-tab v-for="tab in tabs" :key="tab.name">
          <template #title>
            <feather-icon icon="CpuIcon"/>
            <span>{{ tab.title }}</span>
          </template>
          <component :is="tab.name" ref="tabContents"></component>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'

// Tabs
import TabBloodPressure from './TabBloodPressure'
import TabGlucose from './TabGlucose.vue'
import TabGripStrength from './TabGripStrength'
import TabHeight from './TabHeight'
import TabOximetry from './TabOximetry'
import TabTemperature from './TabTemperature'
import TabWeight from './TabWeight'

export default {
  name: 'kioskRecord',
  components: {
    TableSearch,
    TableHeader,
    TableContent,

    // Tabs
    TabBloodPressure,
    TabGlucose,
    TabGripStrength,
    TabHeight,
    TabOximetry,
    TabTemperature,
    TabWeight,
  },

  data() {
    return {
      title: common.getMenuName('kioskRecord'),
      view_permission: common.checkPermission('Kiosk_Menu_Record'),
      tabs: [
        {
          name: 'TabTemperature',
          title: '體溫'
        },
        {
          name: 'TabBloodPressure',
          title: '血壓'
        },
        {
          name: 'TabOximetry',
          title: '血氧'
        },
        {
          name: 'TabWeight',
          title: '體重'
        },
        {
          name: 'TabHeight',
          title: '身高'
        },
        {
          name: 'TabGlucose',
          title: '血糖'
        },
        {
          name: 'TabGripStrength',
          title: '握力'
        },
      ],
      tabIndex: common.getCustomConfigByKey('kioskRecordTabIndex') ?? 0,
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'floorID',
          label: 'common.floor',
          type: 'select',
          options: [],
        },
        {
          field: 'roomID',
          label: 'common.room',
          type: 'select',
          options: []
        },
      ],
      search_default: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId(),
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
      },
    }
  },

  provide() {
    return {
      $refs: this.$refs, // This is intended to provide a <table-search> VM
    }
  },

  mounted() {
    if (!common.isAdmin()) {
      common.getSearchFloorOptions('floorID', common.getRole().facilityID, this)
    } else {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      common.getSearchFloorOptions('floorID', common.getAdminFacilityId(), this)
    }
    common.getSearchRoomOptions('roomID', this.search_default.floorID, this)
    // this.getList()
  },

  methods: {
    getList() {
    },
    runSearch: function () {
      const vmOfSearch = this.$refs.search
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.$refs.tabContents[0].getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false
      })
    },
    onTabChange(tabIndex) {
      common.syncSearchFields({
        ...common.getCustomConfigs(),
        kioskRecordTabIndex: tabIndex,
      })
    }
  }
}
</script>
