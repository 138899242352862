import { render, staticRenderFns } from "./TabGlucose.vue?vue&type=template&id=b1b60fec&scoped=true&"
import script from "./TabGlucose.vue?vue&type=script&lang=js&"
export * from "./TabGlucose.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1b60fec",
  null
  
)

export default component.exports