<template>
  <div>
    <table-header
      ref="header"
      title=""
      :hide-title="true"
      :hide-search="false"
      :searchTips="$t('kiosk.record_search_tips')"
      :button-actions="header_actions"
      @runSearch="runSearch"
    />
    <table-content
      :per-page="page_condition.pageSize"
      :per-page-dropdown-enabled="true"
      @perPageChange="perPageChange"
      :columns="columns"
      :rows="rows"
      :total-rows="total_rows"
      :current-page="current_page"
      :action-options="table_actions"
      @runTableAction="runTableAction"
      @sortChange="sortChange"
      @pageChange="pageChange"
    >
    </table-content>
  </div>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'

export default {
  name: 'TabWeight',
  components: {
    TableHeader,
    TableContent,
  },
  props: {
    parent: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      header_actions: [],
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'member.chineseName',
          field: 'memberName',
          width: '180px',
          changeHTML: function (value, row) {
            let avatar
            if (row.memberPhoto) {
              avatar = common.getServerUrl() + row.memberPhoto
            } else {
              avatar = require('@/assets/images/user.png')
            }
            return `
              <div class="d-flex align-items-center">
                <div class="b-avatar badge-secondary rounded-circle default-photo-bg mr-05">
                  <span class="b-avatar-img">
                    <img src="${avatar}" alt="">
                  </span>
                </div>
                <div>${value}</div>
              </div>
            `
          }
        },
        {
          label: 'common.floor',
          field: 'floorName',
          width: '110px',
        },
        {
          label: 'common.room',
          field: 'roomName',
          width: '110px',
        },
        {
          label: 'common.bed',
          field: 'bedNumber',
          width: '110px',
        },
        {
          label: 'kiosk.device_name',
          field: 'kioskName',
          width: '150px',
        },
        {
          label: 'kiosk.reference_code',
          field: 'referenceCode',
          width: '150px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'kiosk.measure_time',
          field: 'measureTime',
          width: '190px',
          change: this.changeTimeFormat,
        },
        {
          label: this.$t('kiosk.weight') + '(kg)',
          field: 'weight',
        },
      ],
      table_actions: [],
      vmOfSearch: this.$refs.search
    }
  },

  inject: [
    '$refs', // This is intended to inject a <table-search> VM to this.$refs
  ],

  mounted() {
    if(this.rows?.length === 0) {
      this.getList()
    }
  },

  methods: {
    getList() {
      this.$requestWehealth({
        method: 'get',
        url: '/kioskmanage/GetMeasureWeightList',
        params: Object.assign({}, this.getSearchCondition(), this.sort_condition, this.page_condition)
      })
        .then(res => {
          this.total_rows = res.data.total
          this.rows = res.data.measureWeightItems
        })
    },

    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    getSearchCondition: function () {
      this.vmOfSearch.buildSearchCondition()
      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      Object.assign(where, this.vmOfSearch.search_condition)
      return where
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      const vmOfSearch = this.vmOfSearch
      common.syncSearchFields({
        companyID: vmOfSearch.list.companyID,
        facilityID: vmOfSearch.list.facilityID,
        floorID: vmOfSearch.list.floorID,
        roomID: vmOfSearch.list.roomID,
      })
      this.getList()
    },
    runTableAction() {
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>

<style scoped>

</style>
